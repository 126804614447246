<template>
  <div class="diagnosis">
    <div class="diagnosis-banner">
      <div class="diagnosis-banner_content">
        <h1>AI智能辅助诊疗</h1>
        <p>
          依据患者医疗数据和专家知识，运用AI和知识推理引擎为医务工作者提供临床决策的智能应用系统。无缝衔接医院业务流程，深度耦合业务系统，在不干预医生诊疗流程行为下，实时为医务工作者提供最佳诊疗方案，有效提高医务工作者工作效率，提升临床的医疗质量，降低医疗风险。
        </p>
      </div>
    </div>
    <div class="diagnosis-technology">
      <moduleTitle
        titleChinese="核心技术"
        titleEnglish="Core Technology"
      ></moduleTitle>
      <technology></technology>
    </div>
    <div class="diagnosis-framework">
      <moduleTitle
        titleChinese="系统框架"
        titleEnglish="System Framework"
      ></moduleTitle>
      <div class="diagnosis-framework_img">
        <img
          src="@/assets/images/diagnosis-framework.png"
          alt=""
          width="100%"
        />
      </div>
    </div>
    <div class="diagnosis-flowpath">
      <moduleTitle titleChinese="业务流程"></moduleTitle>
      <p class="diagnosis-flowpath_text">
        医护患药共享决策，覆盖门急诊、住院、随访
      </p>
      <div class="diagnosis-flowpath_content">
        <flowpath></flowpath>
      </div>
    </div>
    <div class="diagnosis-diagnosis">
      <moduleTitle
        titleChinese="智能辅助诊断"
        titleEnglish="Intelligent Assisted Diagnosis"
      ></moduleTitle>
      <div class="diagnosis-diagnosis_content">
        <div class="text">
          <h6>自动分析病历内容并给出辅助诊断结果</h6>
          <ul>
            <li>支持<span> 2000+ </span>疾病</li>
            <li>覆盖 <span>33</span> 个学科 <span>90%</span> 常见疾病</li>
            <li>录入症状体征推荐疑似诊断列表</li>
            <li>智能推荐相关鉴别诊断</li>
            <li>相似诊断推荐的辅助检查检验</li>
            <li>自动分析病历内容并给出辅助诊断结果</li>
          </ul>
        </div>
        <div class="img">
          <img
            src="@/assets/images/diagnosis-diagnosis.png"
            alt=""
            width="100%"
          />
        </div>
      </div>
    </div>
    <div class="diagnosis-programme">
      <moduleTitle
        titleChinese="智能推荐治疗方案"
        titleEnglish="Intelligent recommendation of treatment plans"
      ></moduleTitle>
      <div class="diagnosis-diagnosis_content">
        <div class="img">
          <img
            src="@/assets/images/diagnosis-diagnosis.png"
            alt=""
            width="100%"
          />
        </div>
        <div class="text">
          <h6>
            根据临床诊断自动推荐相应的评分表<br />根据临床诊断、评分结果自动推荐治疗方案
          </h6>
          <ul>
            <li>内置 <span>7000+</span> 国际临床指南</li>
            <li><span>110+</span> 中国专家评议</li>
            <li>内置 <span>32</span> 个专科的 <span>100+</span> 评分量表</li>
            <li>根据病历内容智能匹配治疗方案</li>
            <li>支持适应症、禁忌症自动匹配</li>
            <li>倡导临床思维连贯</li>
          </ul>
          <div class="illustrate">
            <p>推荐用药直接查看药品说明书</p>
            <p>推荐手术直接开立手术申请单</p>
            <p>推荐用药直接开立医嘱</p>
            <p>生命体征异常直接查看护理相关记录</p>
          </div>
        </div>
      </div>
    </div>
    <div class="diagnosis-knowledgebase">
      <moduleTitle
        titleChinese="智能提醒（检验）& 临床知识库"
        titleEnglish="Intelligent Reminder (Inspection)&Clinical Knowledge Base"
      ></moduleTitle>
      <div class="diagnosis-knowledgebase_img">
        <img src="@/assets/images/diagnosis-knowledgebase.png" alt="" />
      </div>
    </div>
    <div class="diagnosis-programme">
      <moduleTitle
        titleChinese="智能提醒（检验）& 临床知识库"
        titleEnglish="Intelligent Reminder (Inspection)&Clinical Knowledge Base"
      ></moduleTitle>
      <div class="diagnosis-programme_img">
        <img src="@/assets/images/diagnosis.png" alt="" width="100%">
      </div>
    </div>
    <cooperate></cooperate>
    <foot></foot>
  </div>
</template>

<script>
export default {
  components: {
    foot: () => import("@/components/footer.vue"),
    cooperate: () => import("@/components/cooperate.vue"),
    moduleTitle: () => import("@/components/moduleTitle.vue"),
    technology: () => import("./components/technology.vue"),
    flowpath: () => import("./components/flowpath.vue"),
  },
};
</script>

<style lang="scss">
$content-width: 1200px;
$content-bottom: 90px;
.diagnosis {
  &-banner {
    height: 458px;
    // margin-top: -64px;
    background: url("../../assets/images/auxiliary-banner.png") no-repeat;
    background-size: cover;
    &_content {
      width: 1200px;
      margin: 0 auto;
    }
    h1 {
      font-weight: 500;
      color: #ffffff;
      font-size: 48px;
      padding-top: 108px;
      padding-bottom: 16px;
      margin: 0;
    }
    p {
      width: 480px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.65);
      margin: 0;
    }
  }
  &-technology {
    margin: $content-bottom auto $content-bottom;
    width: $content-width;
  }
  &-framework {
    margin: 0 auto $content-bottom;
    width: $content-width;
  }
  &-flowpath {
    height: 776px;
    background: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(251, 183, 38, 0.05) 100%
    );
    padding-top: 73px;
    padding-bottom: 90px;
    box-sizing: border-box;
    &_text {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      text-align: center;
      margin: 16px 0 107px;
    }
    &_content {
      width: $content-width;
      margin: 0 auto;
    }
    .title h2 {
      margin-bottom: 0;
    }
  }
  &-diagnosis {
    width: $content-width;
    margin: 0 auto 90px auto;
    &_content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .img {
        width: 600px;
      }
      .text {
        flex: 1;
        font-weight: 400;
        color: #000000;
        font-size: 14px;
        span {
          color: #fbb726;
          font-size: 20px;
        }
        h6 {
          font-size: 24px;
          margin: 136px 0 24px 0;
        }
        ul {
          padding: 0;
          li {
            padding-bottom: 8px;
          }
          li::before {
            content: "";
            display: inline-block;
            width: 17px;
            height: 16px;
            background: url("../../assets/images/answer-icon.png") no-repeat;
            background-size: 17px 16px;
            margin-right: 16px;
            margin-top: 0px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  &-programme {
    width: $content-width;
    margin: 0 auto 90px auto;
    .diagnosis-diagnosis_content {
      h6 {
        width: 392px;
        float: right;
        margin-top: 47px;
      }
      ul {
        width: 392px;
        float: right;
        margin: 0;
      }
      .illustrate {
        float: right;
        width: 276px;
        height: 98px;
        background: #f9f9f9;
        color: #808080;
        font-size: 14px;
        padding: 8px;
        box-sizing: border-box;
        margin-right: 84px;
        margin-top: 16px;
        p {
          margin: 0;
          line-height: 21px;
          &::before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 4px;
            background: #808080;
            border-radius: 50%;
            margin-bottom: 3px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  &-knowledgebase {
    height: 906px;
    background: url("../../assets/images/knowledgebase-bg.png") no-repeat;
    background-size: cover;
    padding-top: 64px;
    margin-bottom: 90px;
    img {
      width: $content-width;
      display: block;
      margin: 0 auto;
    }
  }
  &-programme{
    width: 1200px;
    margin: 0 auto 90px;
    &_img{
      width: 100%;
    }
  }
}
</style>