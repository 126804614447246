var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diagnosis"},[_vm._m(0),_c('div',{staticClass:"diagnosis-technology"},[_c('moduleTitle',{attrs:{"titleChinese":"核心技术","titleEnglish":"Core Technology"}}),_c('technology')],1),_c('div',{staticClass:"diagnosis-framework"},[_c('moduleTitle',{attrs:{"titleChinese":"系统框架","titleEnglish":"System Framework"}}),_vm._m(1)],1),_c('div',{staticClass:"diagnosis-flowpath"},[_c('moduleTitle',{attrs:{"titleChinese":"业务流程"}}),_c('p',{staticClass:"diagnosis-flowpath_text"},[_vm._v(" 医护患药共享决策，覆盖门急诊、住院、随访 ")]),_c('div',{staticClass:"diagnosis-flowpath_content"},[_c('flowpath')],1)],1),_c('div',{staticClass:"diagnosis-diagnosis"},[_c('moduleTitle',{attrs:{"titleChinese":"智能辅助诊断","titleEnglish":"Intelligent Assisted Diagnosis"}}),_vm._m(2)],1),_c('div',{staticClass:"diagnosis-programme"},[_c('moduleTitle',{attrs:{"titleChinese":"智能推荐治疗方案","titleEnglish":"Intelligent recommendation of treatment plans"}}),_vm._m(3)],1),_c('div',{staticClass:"diagnosis-knowledgebase"},[_c('moduleTitle',{attrs:{"titleChinese":"智能提醒（检验）& 临床知识库","titleEnglish":"Intelligent Reminder (Inspection)&Clinical Knowledge Base"}}),_vm._m(4)],1),_c('div',{staticClass:"diagnosis-programme"},[_c('moduleTitle',{attrs:{"titleChinese":"智能提醒（检验）& 临床知识库","titleEnglish":"Intelligent Reminder (Inspection)&Clinical Knowledge Base"}}),_vm._m(5)],1),_c('cooperate'),_c('foot')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diagnosis-banner"},[_c('div',{staticClass:"diagnosis-banner_content"},[_c('h1',[_vm._v("AI智能辅助诊疗")]),_c('p',[_vm._v(" 依据患者医疗数据和专家知识，运用AI和知识推理引擎为医务工作者提供临床决策的智能应用系统。无缝衔接医院业务流程，深度耦合业务系统，在不干预医生诊疗流程行为下，实时为医务工作者提供最佳诊疗方案，有效提高医务工作者工作效率，提升临床的医疗质量，降低医疗风险。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diagnosis-framework_img"},[_c('img',{attrs:{"src":require("@/assets/images/diagnosis-framework.png"),"alt":"","width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diagnosis-diagnosis_content"},[_c('div',{staticClass:"text"},[_c('h6',[_vm._v("自动分析病历内容并给出辅助诊断结果")]),_c('ul',[_c('li',[_vm._v("支持"),_c('span',[_vm._v(" 2000+ ")]),_vm._v("疾病")]),_c('li',[_vm._v("覆盖 "),_c('span',[_vm._v("33")]),_vm._v(" 个学科 "),_c('span',[_vm._v("90%")]),_vm._v(" 常见疾病")]),_c('li',[_vm._v("录入症状体征推荐疑似诊断列表")]),_c('li',[_vm._v("智能推荐相关鉴别诊断")]),_c('li',[_vm._v("相似诊断推荐的辅助检查检验")]),_c('li',[_vm._v("自动分析病历内容并给出辅助诊断结果")])])]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/diagnosis-diagnosis.png"),"alt":"","width":"100%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diagnosis-diagnosis_content"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/diagnosis-diagnosis.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"text"},[_c('h6',[_vm._v(" 根据临床诊断自动推荐相应的评分表"),_c('br'),_vm._v("根据临床诊断、评分结果自动推荐治疗方案 ")]),_c('ul',[_c('li',[_vm._v("内置 "),_c('span',[_vm._v("7000+")]),_vm._v(" 国际临床指南")]),_c('li',[_c('span',[_vm._v("110+")]),_vm._v(" 中国专家评议")]),_c('li',[_vm._v("内置 "),_c('span',[_vm._v("32")]),_vm._v(" 个专科的 "),_c('span',[_vm._v("100+")]),_vm._v(" 评分量表")]),_c('li',[_vm._v("根据病历内容智能匹配治疗方案")]),_c('li',[_vm._v("支持适应症、禁忌症自动匹配")]),_c('li',[_vm._v("倡导临床思维连贯")])]),_c('div',{staticClass:"illustrate"},[_c('p',[_vm._v("推荐用药直接查看药品说明书")]),_c('p',[_vm._v("推荐手术直接开立手术申请单")]),_c('p',[_vm._v("推荐用药直接开立医嘱")]),_c('p',[_vm._v("生命体征异常直接查看护理相关记录")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diagnosis-knowledgebase_img"},[_c('img',{attrs:{"src":require("@/assets/images/diagnosis-knowledgebase.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diagnosis-programme_img"},[_c('img',{attrs:{"src":require("@/assets/images/diagnosis.png"),"alt":"","width":"100%"}})])
}]

export { render, staticRenderFns }